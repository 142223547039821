import App from "./js/App";

if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}
document.addEventListener("DOMContentLoaded", () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });

    //Sécu pour démarrer l'animation au top
    let time = 0;
    const checkScrollPosition = setInterval(() => {
        if (time == 100) {
            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
        }
        if (window.scrollY === 0) {
            clearInterval(checkScrollPosition);
            document.querySelector('body').style.opacity = 1;
            new App();
        }
        time+=50;
    }, 50);

    //window.addEventListener('resize', appHeight);
    appHeight();
});

function appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
}
