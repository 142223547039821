import * as bootstrap from 'bootstrap';
import $ from "jquery";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import * as Klaro from "klaro/dist/klaro-no-css";

export default class App {
    constructor() {

        console.log("%cGenesii with ❤", "color: #9ed6a8; padding: 5px 0px 1px; border-bottom:2px solid #9ed6a8;");

        this.init();
        this.initCookie();
    }

    init() {
        const colors = ['blue', 'green', 'orange', 'red', 'purple'];
        let colorClass = 'green'; // Valeur par défaut
        let campaignColor = '';

        const colorParam = this.getUrlParameter('color');
        if (colors.includes(colorParam)) {
            colorClass = colorParam;
            campaignColor = colorParam;
        
            // Ajouter le paramètre de couleur aux liens internes
            this.addParamToInternalLinks(`color=${colorClass}`);
        }

        $('body').addClass(colorClass);

        // Initialiser l'animation GSAP
        if($('body').hasClass('home')) {
            this.initGSAPAnimation();
        }

        if($('[data-action="back"]').length > 0) {
            const currentUrl = window.location.href;
            const lastSlashIndex = currentUrl.lastIndexOf('/');

            if (lastSlashIndex > -1) {
                let currentUrlBack = currentUrl.substring(0, lastSlashIndex);
                console.log(currentUrlBack);
                if (campaignColor) {
                    currentUrlBack += "?color=" + campaignColor;
                    console.log(currentUrlBack);
                }
                $('[data-action="back"]').attr('href', currentUrlBack);
            }
        }


        //change dynamically OG
        $('meta[property="og:url"]').attr('content', window.location.origin);
        $('meta[property="twitter:url"]').attr('content', window.location.origin);

        this.randBank();
    }

    randBank() {
        if($('.slider-banque-rand').length > 0) {
            var items = $('.slider-banque-rand .slider-banque-item');
            var parent = $('.slider-banque-rand');

            items.sort(function() {
                return Math.random() - 0.5;
            });
            parent.empty();

            $.each(items, function(index, item) {
                parent.append(item);
            });
        }
    }

    addParamToInternalLinks(colorClass) {
        $('a[href^="/"], a[href^="./"], a[href^="../"], a[href$=".html"').each(function () {
            let href = $(this).attr('href');
            if (href.includes('?')) {
                href += `&${colorClass}`;
            } else {
                href += `?${colorClass}`;
            }
            $(this).attr('href', href);
        });
    }

    getUrlParameter(name) {
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
        const results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    initGSAPAnimation() {
        $("#star").removeClass('start');
        $('#star').one('transitionend oTransitionEnd transitionend webkitTransitionEnd', function() {
            $("#star").removeClass('animation');
        });

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#header",
                start: "top top",
                end: "bottom center",
                scrub: true,
                onUpdate: (self) => {
                    if (self.progress >= 0.5) {
                        this.triggerAppearAnimations();
                    }
                }
            }
        });

        tl.to("#header", {
            //y: '100vh',
            ease: "none"
        }, 0)
        .to("#header #star", {
            rotation: -5,
            scale: 0.75,
            borderRadius: 7,
            height: (window.innerWidth < 992) ? '30vh' : '50vh',
            width: (window.innerWidth < 992) ? '100%' : '60%',
            left: (window.innerWidth < 992) ? '0' : '20%',
            y: '5vh',
            ease: "none"
        }, 0)
        .to("#header .header__buttons", {
            y: (window.innerWidth < 992) ? '-40vh' : '-10vh',
            opacity: 0,
            //ease: "none"
        }, (window.innerWidth < 992) ? -0.2 : 0)
        .to("#header .scroll-down", {
            y: '-25vh',
            opacity: 0,
            //ease: "none"
        }, -0.2)
        .to("#header .header__title", {
            y: (window.innerWidth < 992) ? '4vh' : '-12vh',
            scale: (window.innerWidth < 992) ? 0.8 : 0.5,
            ease: "none"
        }, 0)
        .to("#header", {
            y: '-40vh',
            ease: "none",
            zIndex: -1,
            opacity: 0
        }, 0.55);

        if(window.innerWidth >= 992) {
            tl.to("#header .header__subtitle", {
                y: '15vh',
                ease: "none"
            }, 0);
        }
    }

    // Méthode pour déclencher les animations d'apparition
    triggerAppearAnimations() {
        const anims = document.querySelectorAll('.title, .opacity, .hidden, .translate-left, .translate-right, .scale');
        anims.forEach(section => {
            gsap.to(section,
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 0.4,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 90%", // Déclenche l'animation lorsque le haut de l'élément entre dans le viewport
                        toggleActions: "play none none none",
                        onEnter: () => {
                            section.classList.remove('hidden');
                            section.classList.remove('translate-left');
                            section.classList.remove('translate-right');
                            section.classList.remove('scale');
                            section.classList.remove('opacity');
                            section.classList.remove('title');
                        }
                    }
                }
            );
        });
    }

    initCookie() {
        const config = {
            default: true,
            htmlTexts: true,
            acceptAll: true,
            translations: {
                en: {
                    decline: "Decline",
                    save: "Save",
                    ok: "Accept",
                    acceptAll: "Accept",
                    acceptSelected: "Save my choices",
                    close: "Close",
                    consentNotice: {
                        changeDescription: "There were changes since your last visit, please renew your consent.",
                        description: "<strong>Roll in the cookies</strong> <br><br> Our site and its partners use cookies to measure the audience and the performance of the site, and thus improve the user experience. <br><br> You can change your choices at any time in the \"Manage Cookies\" section of our site. <br>" +
                            "<br><br>For more information, please see our cookie use policy. ",
                        learnMore: "Manage cookies",
                    },

                    consentModal: {
                        title: 'Cookie management',
                        description: "Our site and its partners use cookies to measure the audience and the performance of the site, and thus improve the user experience." +
                            "<br> <br> Choose which categories of cookies you want us to collect. This choice will be saved for 6 months. Note that blocking certain types of cookies may degrade your browsing experience. <br><br>" +
                            "For more information, please see our cookie use policy. <br><br><strong>Technical</strong></br></br>" +
                            "These cookies enable the website to provide enhanced functionality, personalisation, or to carry out the actions you intiate (e.g. remembering your cookies choices) and cannot be disabled as they do not require your consent. They may be set by us or by third party providers whose services we have added to our pages."
                    },
                    inlineTracker: {
                        description: 'Example of an inline tracking script',
                    },
                    externalTracker: {
                        description: 'Example of an external tracking script',
                    },
                },
                fr: {
                    consentNotice: {
                        description: "Notre site utilise des cookies à des fins de suivi statistique et marketing, pour observer et mieux comprendre l'utilisation qui en est faite. Vous avez le choix d'accepter ou non.",
                    },
                }
            },
            purposes: {
                advertising: {
                    description: "",
                    title: "Advertising"
                },
                functional: {
                    description: "These services are essential for the correct functioning of this website. You cannot disable them here as the service would not work correctly otherwise.\n",
                    title: "Service Provision"
                },
                marketing: {
                    description: "The purpose of these cookies is to analyze traffic and use made of the site, to better understand how it is used and to be able to improve your browsing experience. These cookies may in particular process data relating to the pages visited, the durations during which the pages are visited, the browser you use to visit these pages, etc. The use and deposit of these cookies is subject to obtaining your consent.",
                    title: "Marketing"
                },
                // performance: {
                //   description: "These services process personal information to optimize the service that this website offers.\n",
                //   title: "Performance Optimization"
                // }
            },

            services: [
                {
                    name: 'google-tag-manager',
                    required: true,
                    cookies: [
                        /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
                    ],
                    purposes: ['marketing'],
                    onAccept: `
                // we notify the tag manager about all services that were accepted. You can define
                // a custom event in GTM to load the service if consent was given.
                for(let k of Object.keys(opts.consents)){
                    if (opts.consents[k]){
                        let eventName = 'klaro-'+k+'-accepted'
                        dataLayer.push({'event': eventName})
                    }
                }
            `,
                    onDecline: `
                gtag('consent', 'update', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
                gtag('set', 'ads_data_redaction', false)
          `,
                    onInit: `
                // initialization code here (will be executed only once per page-load)
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments)}
                gtag('consent', 'default', {'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted'})
                gtag('set', 'ads_data_redaction', true)
            `,
                },
            ]

        };

// we assign the Klaro module to the window, so that we can access it in JS
        window.klaro = Klaro;
        window.klaroConfig = config;
// we set up Klaro with the config
        Klaro.setup(config);
        klaro.language("fr")

        $('.js').click(function (e) {
            e.preventDefault();
            klaro.show()
        })

    }
}
